import { useEffect, useRef, useState } from "react";

import "../styles/style.css";
import Tilt from "react-parallax-tilt";
import { useHistory } from "react-router-dom";

import odisha from "../../assets/projectThumbnail/odisha.png";
import odishaAVIF from "../../assets/projectThumbnail/avif/odishaAVIF.avif";
import odishaWebp from "../../assets/projectThumbnail/webp/odishaWebp.webp";

import axar from "../../assets/projectThumbnail/axar.png";
import axarAVIF from "../../assets/projectThumbnail/avif/axarAVIF.avif";
import axarWebp from "../../assets/projectThumbnail/webp/axarWebp.webp";

import ein from "../../assets/projectThumbnail/ein.png";
import einAVIF from "../../assets/projectThumbnail/avif/einAVIF.avif";
import einWebp from "../../assets/projectThumbnail/webp/einWebp.webp";

import dhyana1 from "../../assets/projectThumbnail/dhyana1.png";
import dhyana1AVIF from "../../assets/projectThumbnail/avif/dhyana1AVIF.avif";
import dhyana1Webp from "../../assets/projectThumbnail/webp/dhyana1Webp.webp";

import holobot from "../../assets/projectThumbnail/holobot.png";
import holobotAVIF from "../../assets/projectThumbnail/avif/holobotAVIF.avif";
import holobotWebp from "../../assets/projectThumbnail/webp/holobotWebp.webp";

import dhyana2 from "../../assets/projectThumbnail/dhyana2.png";
import dhyana2AVIF from "../../assets/projectThumbnail/avif/dhyana2AVIF.avif";
import dhyana2Webp from "../../assets/projectThumbnail/webp/dhyana2Webp.webp";

const projectData = [
  {
    id: 0,
    img: dhyana2,
    imgWebp: dhyana2Webp,
    imgAVIF: dhyana2AVIF,
    title: "Dhyana 2 - The smart ring that balances your body and mind",
    url: "Dhyana2-The-smart-ring-that-balances-your-body-and-mind",
    genre: [
      "Hardware Development",
      "Industrial Design",
      "Mobile App Development",
      "UI/UX Design",
      "Web Development",
    ],
  },

  {
    id: 1,
    img: dhyana1,
    imgWebp: dhyana1Webp,
    imgAVIF: dhyana1AVIF,
    title: "Dhyana - The first smart ring that measures your mindfulness",
    url: "Dhyana-The-first-smart-ring-that-measures-your-mindfulness",
    genre: [
      "Hardware Development",
      "Industrial Design",
      "Mobile App Development",
      "UI/UX Design",
      "Web Development",
    ],
  },

  {
    id: 2,
    img: holobot,
    imgWebp: holobotWebp,
    imgAVIF: holobotAVIF,
    title: "Holobot - A human like hologram that interacts with you",
    url: "Holobot-A-human-like-hologram-that-interacts-with-you",
    genre: [
      "Artificial Intelligence",
      "Hardware Development",
      "Software Development",
    ],
  },
];

const projectData1 = [
  {
    id: 0,
    img: odisha,
    imgWebp: odishaWebp,
    imgAVIF: odishaAVIF,
    title: "Mo Naveen Mo Ghare - CM takes a selfie with 10 Million voters",
    url: "Mo-Naveen-Mo-Ghare-CM-takes-a-selfie-with-10-Million-voters",
    genre: [
      "Artificial Intelligence",
      "Augmented Reality",
      "Mobile App Development",
      "Political Strategy",
      "Software Development",
      "UI/UX Design",
    ],
  },

  {
    id: 1,
    img: ein,
    imgWebp: einWebp,
    imgAVIF: einAVIF,
    title: "Ein - A cardiologist, nurse and care-giver on the wrist",
    url: "Ein-A-cardiologist-nurse-and-care-giver-on-the-wrist",
    genre: ["Hardware Development", "Industrial Design", "UI/UX Design"],
  },

  {
    id: 2,
    img: axar,
    imgWebp: axarWebp,
    imgAVIF: axarAVIF,
    title: "ARxAR - Creating AR Rahman’s personal AR application",
    url: "ARxAR-Creating-AR-Rahmans-personal-AR-application",
    genre: ["Augmented Reality", "Mobile App Development", "UI/UX Design"],
  },
];

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      {
        rootMargin,
      }
    );

    element.current && observer.observe(element.current);

    return () => {
      observer.unobserve(element.current);
    };
  }, [element, rootMargin]);

  return isVisible;
};

function SecondComponent() {
  const ref = useRef();
  const ref1 = useRef();

  const inViewport = useIntersection(ref, "0px");

  const inViewport1 = useIntersection(ref1, "-50px");

  if (inViewport) {
    // console.log("in viewport:", ref.current);
  }

  const history = useHistory();

  const NavigateProjectPage = (pathName) => {
    history.push(pathName);
    window.scroll({
      top: 0,
      left: 0,
    });
  };

  const navigateProject = () => {
    setTimeout(() => {
      history.push("/project");
      window.scroll({
        top: 0,
        left: 0,
      });
    }, 10);
  };

  return (
    <div>
      <div className="newDiv" ref={ref}>
        <div
          className="wrapper cssanimation"
          id={inViewport ? "fadeInBottom" : "notVisible"}
        >
          {projectData.slice(0, 3).map((project, index) => (
            <div className="wrapper-item" key={index}>
              <div
                onClick={() => NavigateProjectPage(`/project/${project.url}`)}
              >
                <Tilt
                  className="parallax-effect-glare-scale"
                  tiltMaxAngleX={10}
                  tiltMaxAngleY={10}
                  perspective={1000}
                  transitionSpeed={1500}
                  gyroscope={false}
                >
                  <picture>
                    <source srcSet={project.imgAVIF} type="image/avif" />
                    <source srcSet={project.imgWebp} type="image/webp" />
                    <img
                      src={project.img}
                      alt={project.title}
                      width="100%"
                      height="100%"
                    />
                  </picture>
                  <div className="inner-element">
                    <p>{project.title}</p>
                  </div>
                </Tilt>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="newDiv" ref={ref1}>
        <div
          className="wrapper cssanimation"
          id={inViewport1 ? "fadeInBottom" : "notVisible"}
        >
          {projectData1.slice(0, 3).map((project, index) => (
            <div className="wrapper-item" key={index}>
              <div
                onClick={() => NavigateProjectPage(`/project/${project.url}`)}
              >
                <Tilt
                  className="parallax-effect-glare-scale"
                  tiltMaxAngleX={10}
                  tiltMaxAngleY={10}
                  perspective={1000}
                  transitionSpeed={1500}
                  gyroscope={false}
                >
                  <picture>
                    <source srcSet={project.imgAVIF} type="image/avif" />
                    <source srcSet={project.imgWebp} type="image/webp" />
                    <img
                      src={project.img}
                      alt={project.title}
                      width="100%"
                      height="100%"
                    />
                  </picture>
                  <div className="inner-element">
                    <p>{project.title}</p>
                  </div>
                </Tilt>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="containerDiv">
        <div className="center">
          <button
            type="button"
            onClick={() => navigateProject()}
            className="buttonLoadHome"
          >
            Show more projects
          </button>
        </div>
      </div>
    </div>
  );
}

export default SecondComponent;
