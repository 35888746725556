import React from "react";
import "../styles/style.css";
import styled from "styled-components";
import video from "../../assets/video.svg";
import low from "../../assets/low.png";
import LazyImage from "./lazyImage";

const Para1 = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3.125em;
  line-height: 145%;
  color: #32425c;
  text-align: center;
  margin: 0;
`;

const Para2 = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #32425c;
  text-align: center;
  font-size: 1.25em;
  line-height: 145%;
  margin: 0;
  @media only screen and (max-width: 767px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const ImgDiv = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const FirstComponent = () => {
  return (
    <div>
      <ImgDiv>
        <LazyImage
          className="demo"
          src={video}
          lqip={low}
          // lqip="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAUACYDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAQGBwEF/8QALhAAAgEDAgQEBAcAAAAAAAAAAQIDAAQRBRIGITFBBxMikTJRgbEVFkJhYnGh/8QAFgEBAQEAAAAAAAAAAAAAAAAABAMC/8QAHhEAAgICAgMAAAAAAAAAAAAAAAIBAxESITEyM1H/2gAMAwEAAhEDEQA/AKdpOiaOHUvYzs2wJkZwB860Dg/QbKxvIntLZkLyB2Dsctjn3qPZ6JqDoRHPatjphtpHuKi8TaxJwzabblliunOEO4MCRjv9aKuWbECZ1WMlz8QdJGu2MrpBDaX1uoeKbzAQe+04+/Y1n35cW5tUubGdkWT48+rDd/8Aar3F3G9xqkCx2mYIgm2RlyDI3T2PXlUHw/1S/E2oWspmWJlEkLHKq20AMA3TuOVVsqbTJiHSW1JGqcIzysQ1yylXOCUxkUr0bi6uJG3Sv8PpCh8/WlRiW+mtENe06OMBB5antzH7VmvjnaRbbaQAq8YAUj+XI/YUpSa/Ii/RXNI0Kxn8Fte1x4z+J2N5EkMwPRSUBGOn6z7D5VbdFUbEhb1RhgmG59uZ/ulKdVyjg7vZWdvNJtJcF4+eTzHKlKUAcf/Z"
          alt="render image"
        />
      </ImgDiv>
      <div>
        <Para2>We are experts at transforming ideas into amazing </Para2>

        <div className="container_row">
          <div className="layer1">
            <Para1>Products</Para1>
          </div>
          <div className="layer2">
            <Para1>Services</Para1>
          </div>
          <div className="layer3">
            <Para1>Experiences</Para1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;
